<template>
  <span>

    <span class="btn btn-view"
          @click="showProjectModal(project)"
    >
      <font-awesome-icon icon="eye" />
    </span>

      <!-- Task modal -->
    <b-modal ref="task-modal"
             @hide="hideTaskModal"
             :size="modalSize"
             modal-class="task-modal mapping-modal"
    >
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideTaskModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center">
            {{ $t('projects.assessment.view_label') }}
          </h5>
          <div class="row mt-2 pt-4 pb-1" v-if="getGuide('system_assessment')">
            <div class="col-sm-12">
              <b-form-group>
                <div class="">
                  <div v-if="getGuide('system_assessment')" class="mb-2">
                    <GuideButton :guide="getGuide('system_assessment')"></GuideButton>
                  </div>
                  <div v-if="wpSystemAssessmentGuide && wpSystemAssessmentGuide.id" class="guide-content-container">
                    <p v-if="!showFullSystemAssessmentGuideDescription" v-html="wpSystemAssessmentGuide.excerpt.rendered"></p>
                    <p v-if="showFullSystemAssessmentGuideDescription" v-html="wpSystemAssessmentGuide.content.rendered"></p>

                    <div class="text-center mt-3" @click="showFullSystemAssessmentGuideDescription=!showFullSystemAssessmentGuideDescription">
                      <span class="cursor-pointer" v-if="!showFullSystemAssessmentGuideDescription">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullSystemAssessmentGuideDescription">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
      </template>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form qid="create-p-form">
        <div class="form-container mb-5">
          <b-form-group class="mb-4">
            <div v-if="selectedProject">
              <label class="mt-3">
                {{ $t('projects.assessment.assessment_project_name') }}
              </label>
              <div>
                <router-link
                  :to="{ name: 'projects.view', params: { slug: selectedProject.slug }}"
                  qid="s-view-page-link"
                >
                  <font-awesome-icon icon="file-alt" size="lg" class="mr-2 text-success"/>
                  {{ selectedProject.title }}
                </router-link>
              </div>
            </div>
            <div>
              <label class="mt-3">
                {{ $t('projects.assessment.assessment_template_name') }}
              </label>
              <div>
                <span v-if="selectedProject.assessment">
                  <font-awesome-icon icon="file-alt" size="lg" class="mr-2"/>
                  {{ selectedProject.assessment.name }}
                </span>
              </div>
            </div>
            <div>
              <label class="mt-3">
                {{ $t('projects.assessment.content_name') }}
              </label>
              <div>
                <span class="" v-if="object">
                  <font-awesome-icon icon="desktop"
                                     size="lg"
                                     class="mr-2"
                                     v-if="object.who_am_i === 'App\\System\\Models\\System'"
                  />
                  <font-awesome-icon icon="cubes"
                                     size="lg"
                                     class="mr-2"
                                     v-if="object.who_am_i === 'App\\Activity\\Models\\Activity'"
                  />
                  <font-awesome-icon icon="cube"
                                     size="lg"
                                     class="mr-2"
                                     v-if="object.who_am_i === 'App\\Process\\Models\\Process'"
                  />
                  <font-awesome-icon icon="file-signature"
                                     size="lg"
                                     class="mr-2"
                                     v-if="object.who_am_i === 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'"
                  />
                  {{ object.name }}
                </span>
              </div>
            </div>
          </b-form-group>
        </div>
      </b-form>

      <h5 v-if="tasks && tasks.length">{{ $t('maintenance.assessments.questions_label') }}</h5>
      <table class="table table-responsive-md table-list-secondary" v-if="tasks && tasks.length">
        <thead>
        <tr>
          <th>
            {{ $t('projects.table.name') }}
          </th>

          <th v-if="selectedProject.type === 'risk_assessment' || selectedProject.type === 'control_assessment'">
            {{ $t('system.response') }}
          </th>

          <th class="text-center"
              v-if="selectedProject.type === 'security_measure' || selectedProject.type === 'control' || selectedProject.type === 'risk_assessment' || selectedProject.type === 'control_assessment'">
            {{ $t('table.attachment') }}
          </th>

          <th>
            {{ $t('system.last_updated') }}
          </th>
        </tr>
        </thead>

        <tbody v-if="tasks && tasks.length">
          <tr v-for="(object, index) in tasks"
              :key="'table-row-' + index"
              :id="'table-row-' + index"
          >
            <b-popover
                :target="'table-row-' + index"
                boundary="viewport"
                v-if="object.code === 'system_impact_assessment' || object.code === 'risk_assessment' || object.code === 'control_assessment'"
                triggers="hover"
                placement="topleft"
                :delay="{ show: 500, hide: 0 }"
            >
              <div v-if="object.code === 'system_impact_assessment'">
                <b-form-group>
                  <label>
                    {{ $t('processes.confidentiality.title') }}
                  </label>
                  <p v-if="object.confidentiality">
                    {{ object.confidentiality }}
                  </p>
                  <p v-if="!object.confidentiality">
                    {{ $t('system.no_results') }}
                  </p>
                </b-form-group>
                <b-form-group>
                  <label>
                    {{ $t('processes.integrity.title') }}
                  </label>
                  <p v-if="object.integrity">
                    {{ object.integrity }}
                  </p>
                  <p v-if="!object.integrity">
                    {{ $t('system.no_results') }}
                  </p>
                </b-form-group>
                <b-form-group>
                  <label>
                    {{ $t('processes.availability.title') }}
                  </label>
                  <p v-if="object.availability">
                    {{ object.availability }}
                  </p>
                  <p v-if="!object.availability">
                    {{ $t('system.no_results') }}
                  </p>
                </b-form-group>
                <b-form-group>
                  <label>
                    {{ $t('tasks.modal.rto_label') }}
                  </label>
                  <p v-if="object.rto_number">
                    {{ object.rto_number }} {{ object.rto_type }}
                  </p>
                  <p v-if="!object.rto_number">
                    {{ $t('system.no_results') }}
                  </p>
                </b-form-group>
                <b-form-group>
                  <label>
                    {{ $t('tasks.modal.rpo_label') }}
                  </label>
                  <p v-if="object.rpo_number">
                    {{ object.rpo_number }} {{ object.rpo_type }}
                  </p>
                  <p v-if="!object.rpo_number">
                    {{ $t('system.no_results') }}
                  </p>
                </b-form-group>
                <b-form-group>
                  <label>
                    {{ $t('tasks.modal.remarks_label') }}
                  </label>
                  <p class="text-formatted" v-if="object.remarks">{{ object.remarks }}</p>
                  <p v-if="!object.remarks">{{ $t('system.no_results') }}</p>
                </b-form-group>
              </div>
              <div v-if="object.code === 'risk_assessment'">
                <b-form-group>
                  <label>
                    {{ $t('tasks.modal.response_label') }}
                  </label>
                  <p v-if="object.response">
                    {{ object.response }}
                  </p>
                  <p v-if="!object.response">
                    {{ $t('system.no_results') }}
                  </p>
                </b-form-group>
                <b-form-group>
                  <label>
                    {{ $t('tasks.modal.remarks_label') }}
                  </label>
                  <p class="text-formatted" v-if="object.remarks">{{ object.remarks }}</p>
                  <p v-if="!object.remarks">
                    {{ $t('system.no_results') }}
                  </p>
                </b-form-group>
                <b-form-group>
                  <label>
                    {{ $t('maintenance.policies.create.attachments') }}
                  </label>
                  <p>
                    <span v-if="object.has_attachment">
                      <font-awesome-icon icon="paperclip"/>
                    </span>
                    <span v-if="!object.has_attachment">
                      {{ $t('system.no_results') }}
                    </span>
                  </p>
                </b-form-group>
              </div>
              <div v-if="object.code === 'control_assessment'">
                <b-form-group v-if="object.users && object.users.length">
                  <label>
                    {{ $t('tasks.table.task_assignee') }}
                  </label>
                  <div class="mb-1" v-for="(user, user_index) in object.users" :key="user_index">
                    <span>
                      <Avatar :object="user" size="xs"></Avatar>
                      {{ user.name }}
                    </span>
                  </div>
                </b-form-group>
                <b-form-group v-if="object.status">
                  <label>
                    {{ $t('tasks.table.task_status') }}
                  </label>
                  <p v-if="object.status">
                    <span v-if="object.status === 'completed'" >
                      <font-awesome-icon icon="check-circle" class="icon-success"/>
                    </span>
                    <span v-if="object.status === 'pending'" class="pr-2">
                      <font-awesome-icon
                          icon="exclamation-triangle"
                          class="risk-6"
                      />
                    </span>
                    {{ $t('system.' + $options.filters.slugify(object.status)) }}
                  </p>
                </b-form-group>
                <b-form-group v-if="object.response">
                  <label>
                    {{ $t('tasks.modal.response_label') }}
                  </label>
                  <div v-if="object.response">
                    <span v-if="object.response_type === 'Datepicker'">
                      <font-awesome-icon icon="calendar-alt" class="mr-2"/>
                      {{ object.response | formatDate }}
                    </span>
                    <span v-else>
                      <font-awesome-icon icon="check-circle" class="icon-success" v-if="getIcon(object) === 'Positive'"/>
                      <font-awesome-icon icon="times-circle" class="icon-danger" v-if="getIcon(object) === 'Negative'"/>
                      <font-awesome-icon icon="info-circle" class="icon-info" v-if="getIcon(object) === 'Neutral'"/>
                      <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="getIcon(object) === 'Very low'"/>
                      <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="getIcon(object) === 'Low'"/>
                      <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="getIcon(object) === 'High'"/>
                      <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="getIcon(object) === 'Very high'"/>
                      {{ object.response }}
                    </span>
                  </div>
                </b-form-group>
                <b-form-group v-if="object.control_description">
                  <label>
                    {{ $t('tasks.modal.control_description_label') }}
                  </label>
                  <p class="text-formatted">{{ object.control_description }}</p>
                </b-form-group>
                <b-form-group v-if="object.performed_text">
                  <label>
                    {{ $t('tasks.modal.control_description_label') }}
                  </label>
                  <p class="text-formatted">{{ object.performed_text }}</p>
                </b-form-group>
                <b-form-group v-if="object.remarks">
                  <label>
                    {{ $t('tasks.modal.remarks_label') }}
                  </label>
                  <p class="text-formatted">{{ object.remarks }}</p>
                </b-form-group>
                <b-form-group v-if="object.data_users && object.data_users.length">
                  <label>
                    {{ $t('tasks.table.data_collection_assignee') }}
                  </label>
                  <div class="mb-1" v-for="(user, data_user_index) in object.data_users" :key="data_user_index">
                    <span>
                      <Avatar :object="user" size="xs"></Avatar>
                      {{ user.name }}
                    </span>
                  </div>
                </b-form-group>
                <b-form-group v-if="object.data_remarks">
                  <label>
                    {{ $t('tasks.modal.comment_label') }}
                  </label>
                  <p class="text-formatted">{{ object.data_remarks }}</p>
                </b-form-group>
                <b-form-group>
                  <label>
                    {{ $t('maintenance.policies.create.attachments') }}
                  </label>
                  <p>
                    <span v-if="(object.media && object.media.length) || (object.links && object.links.length)">
                      {{ $t('system.yes') }}
                    </span>
                    <span v-else>
                      {{ $t('system.no') }}
                    </span>
                  </p>
                </b-form-group>
              </div>
            </b-popover>

            <td>
              <p>
                <span>{{ object.title}}</span>
              </p>
            </td>

            <td v-if="selectedProject.type === 'risk_assessment'">
              <span v-if="object.response && object.response.length">
                <span v-if="object.response_status === 'positive'" >
                    <font-awesome-icon icon="check-circle" class="icon-success"/>
                </span>
                <span v-if="object.response_status === 'negative'" >
                    <font-awesome-icon icon="times-circle" class="icon-danger"/>
                </span>
                <span v-if="object.response_status === 'neutral'" class="badge-pill-small badge-info mr-2">
                  <font-awesome-icon icon="info" size="xs"/>
                </span>
                <span v-if="object.response_status === 'pending'" class="badge-pill-small badge-warning mr-2">
                  <font-awesome-icon icon="info" size="xs"/>
                </span>
                {{ object.response | capitalize }}
              </span>
            </td>

            <td v-if="selectedProject.type === 'control_assessment'">
              <span v-if="object.response && object.response.length">
                <span v-if="object.response_status && object.response_status.length" >
                  <font-awesome-icon icon="check-circle" class="icon-success" v-if="getIcon(object) === 'Positive'"/>
                  <font-awesome-icon icon="times-circle" class="icon-danger" v-if="getIcon(object) === 'Negative'"/>
                  <font-awesome-icon icon="info-circle" class="icon-info" v-if="getIcon(object) === 'Neutral'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="getIcon(object) === 'Very low'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="getIcon(object) === 'Low'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="getIcon(object) === 'High'"/>
                  <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="getIcon(object) === 'Very high'"/>
                </span>

                {{ object.response | capitalize }}
              </span >
            </td>

            <td v-if="selectedProject.type === 'security_measure' || selectedProject.type === 'control' || selectedProject.type === 'risk_assessment' || selectedProject.type === 'control_assessment'"
                class="text-center">
              <span v-if="(object.media && object.media.length) || (object.links && object.links.length)">
                <font-awesome-icon icon="paperclip"/>
              </span>
            </td>

            <td>
              <span class="text-normal">
                  {{ object.updated_at | formatDate }}
              </span>
            </td>
          </tr>
        </tbody>

        <tbody v-if="!loading && tasks && !tasks.length">
          <tr class="text-center">
            <td colspan="10">
              <h5 class="mt-3">
                {{ $t('system.no_results') }}
              </h5>
            </td>
          </tr>
        </tbody>

        <tbody v-if="loading && !tasks.length">
          <tr class="text-center">
            <td colspan="10">
              <div class="mt-2 mb-4">
                <div class="swing-container">
                  <div class="swing">
                    <div class="swing-l"></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div class="swing-r"></div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>


      <div v-for="(selectedTask, index) in systemRisks" class="mb-5" :key="index">
        <div class="col-sm-12 pl-0" v-if="selectedTask">
          <div v-if="selectedTask.scope && selectedTask.code === 'system_impact_assessment'">
            <h6 style="color:black;">
              <span v-if="selectedTask.scope">
                <font-awesome-icon
                    icon="desktop"
                    size="lg"
                    class="mr-2"
                    v-if="selectedTask.scope.who_am_i === 'App\\System\\Models\\System'"
                />
                <font-awesome-icon
                    icon="cubes"
                    size="lg"
                    class="mr-2"
                    v-if="selectedTask.scope.who_am_i === 'App\\Activity\\Models\\Activity'"
                />
                <font-awesome-icon
                    icon="cube"
                    size="lg"
                    class="mr-2"
                    v-if="selectedTask.scope.who_am_i === 'App\\Process\\Models\\Process'"
                />
                <font-awesome-icon
                    icon="file-signature"
                    size="lg"
                    class="mr-2"
                    v-if="selectedTask.scope.who_am_i === 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'"
                />
              </span>
              {{ selectedTask.scope.name }}
            </h6>
          </div>
        </div>
        <div class="row mt-4 pr-0">
          <div class="col-sm-12">
            <div>
              <label>
                {{ $t('processes.confidentiality.title') }}
              </label>
              <p v-if="selectedTask.confidentiality">
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-1 mr-2'" v-if="selectedTask.confidentiality === 'Very low'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-3 mr-2'" v-if="selectedTask.confidentiality === 'Low'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-9 mr-2'" v-if="selectedTask.confidentiality === 'High'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-16 mr-2'" v-if="selectedTask.confidentiality === 'Very high'"/>
                {{selectedTask.confidentiality}}
              </p>
              <p v-else>{{ $t('risk.missing_input')}}</p>
            </div>
            <div class="mt-3">
              <label>
                {{ $t('processes.integrity.title') }}
              </label>
              <p v-if="selectedTask.integrity">
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-1 mr-2'" v-if="selectedTask.integrity === 'Very low'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-3 mr-2'" v-if="selectedTask.integrity === 'Low'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-9 mr-2'" v-if="selectedTask.integrity === 'High'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-16 mr-2'" v-if="selectedTask.integrity === 'Very high'"/>
                {{selectedTask.integrity}}
              </p>
              <p v-else>{{ $t('risk.missing_input')}}</p>
            </div>
            <div class="mt-3">
              <label>
                {{ $t('processes.availability.title') }}
              </label>
              <p v-if="selectedTask.availability">
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-1 mr-2'" v-if="selectedTask.availability === 'Very low'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-3 mr-2'" v-if="selectedTask.availability === 'Low'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-9 mr-2'" v-if="selectedTask.availability === 'High'"/>
                <font-awesome-icon icon="exclamation-triangle" :class="'risk-16 mr-2'" v-if="selectedTask.availability === 'Very high'"/>
                {{selectedTask.availability}}
              </p>
              <p v-else>{{ $t('risk.missing_input')}}</p>
            </div>
            <div class="mt-3">
              <label>
                {{ $t('tasks.modal.rpo_label') }}
              </label>
              <p v-if="selectedTask.rpo_number || selectedTask.rpo_type">{{selectedTask.rpo_number}} {{selectedTask.rpo_type}}</p>
              <p v-else>{{ $t('risk.missing_input')}}</p>
            </div>
            <div class="mt-3">
              <label>
                {{ $t('tasks.modal.rto_label') }}
              </label>
              <p v-if="selectedTask.rto_number || selectedTask.rto_type">{{selectedTask.rto_number}} {{selectedTask.rto_type}}</p>
              <p v-else>{{ $t('risk.missing_input')}}</p>
            </div>
            <div class="mt-3">
              <label>
                {{ $t('tasks.modal.remarks_label') }}
              </label>
              <p class="text-formatted" v-if="selectedTask.remarks">{{selectedTask.remarks}}</p>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button
                      class="mt-2 mb-2 ml-3 mr-0"
                      variant="secondary"
                      @click="hideTaskModal()"
                      qid="create-ac-submit-button"
                  >
                    {{ $t('system.close') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

    <ConfirmRemoveAttachment
            :object="selectedFile"
            :show="showDeleteAttachmentModal"
            type="file"
            @closeConfirmModal="closeDeleteAttachmentModal"
    ></ConfirmRemoveAttachment>

    <ConfirmRemoveAttachment
            :object="selectedLink"
            :show="showDeleteLinkModal"
            type="link"
            @closeConfirmModal="closeDeleteLinkModal"
    ></ConfirmRemoveAttachment>

  </span>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js';
  import Avatar from '@/components/Avatar.vue';
  import {config} from '@/config.js';
  import ConfirmRemoveAttachment from '@/components/ConfirmRemoveAttachment.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'AssessmentViewSingleTaskModal',
    data: function () {
      return {
        taskForm: {
          remarks: '',
          rto: '',
          rpo: ''
        },
        selectedProject: [],
        buttonDisabled: false,
        selectedFile: {},
        selectedLink: {},
        showDeleteAttachmentModal: false,
        showDeleteLinkModal: false,
        showInput: false,
        showSingleLinkLabel: true,
        inputLink: '',
        descriptionLength: config.descriptionLength,
        showFullProjectMessage: false,
        showFullTaskDescription: false,
        showFullGenericControlDescription: false,
        error: '',
        loading: true,
        taskList: [],
        selectedAnswer: [],
        allAnswers: [],
        impactItems: [
          {name: this.$t('impact.very_low'), value: 'Very low'},
          {name: this.$t('impact.low'), value: 'Low'},
          {name: this.$t('impact.high'), value: 'High'},
          {name: this.$t('impact.very_high'), value: 'Very high'}
        ],
        intervalOptions: [
          {text: this.$t('system.interval_seconds'), value: 'Seconds'},
          {text: this.$t('system.interval_minutes'), value: 'Minutes'},
          {text: this.$t('system.interval_hours'), value: 'Hours'},
          {text: this.$t('system.interval_days'), value: 'Days'},
          {text: this.$t('system.interval_months'), value: 'Months'},
          {text: this.$t('system.interval_years'), value: 'Years'},
        ],

        showFullSystemAssessmentGuideDescription: false,
        wpSystemAssessmentGuide: {},
        myGuides: [],

        selectedConfidentialityImpact: [],
        selectedIntegrityImpact: [],
        selectedAvailabilityImpact: [],
        rtoInterval: [],
        rpoInterval: [],
        tasks: {},
        systemRisks: {},
        modalSize: 'sm'
      }
    },
    props: {
      project: {
        type: Object
      },
      object: {
        type: Object
      }
    },
    components: {
      Avatar,
      ConfirmRemoveAttachment,
      GuideButton
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getWordpressLink() {
        return this.$store.state.wordpressLink
      },
    },
    watch: {
      getClient: function () {

      },
      includeScheduled: function () {
        this.loadCA()
      }
    },
    methods: {
      showProjectModal(object) {
        let self = this;
        this.error = '';

        if (object != null) {
          self.selectedProject = object
        }

        // Table items
        this.loadTasks();
        this.loadSystemRisks();
        this.$refs['task-modal'].show()
      },
      hideTaskModal() {
        this.error = '';
        this.$emit('reloadContent');

        this.selectedProject = {}
        this.$refs['task-modal'].hide()
      },
      resetFile() {
        let imagefile = document.querySelector('#file');
        imagefile.value = '';
      },
      openDeleteAttachmentModal(object) {
        this.selectedFile = object
        this.showDeleteAttachmentModal = true
      },
      closeDeleteAttachmentModal() {
        this.selectedFile = {}
        this.showDeleteAttachmentModal = false;
        this.loadTaskById(this.selectedProject.id, true);
      },
      openDeleteLinkModal(object) {
        this.selectedLink = object
        this.showDeleteLinkModal = true
      },
      closeDeleteLinkModal() {
        this.selectedLink = {}
        this.showDeleteLinkModal = false;
        this.loadTaskById(this.selectedProject.id, true);
      },
      handleLink() {
        let self = this;
        let formData = new FormData();

        if (!this.inputLink.startsWith('http')) {
          this.inputLink = 'http://' + this.inputLink
        }

        formData.append("url", this.inputLink);
        formData.append("model_type", this.selectedProject.who_am_i);
        formData.append("model_id", this.selectedProject.id);

        piincHttp.post('links', formData).then(function () {
          self.inputLink = ''
          self.loadTaskById(self.selectedProject.id, true);
          self.showInput = false
        }, function () {
        })
      },
      downloadMedia(object) {
        piincHttp.get('documents/' + object.id + '/download', {responseType: 'blob'}).then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = object.file_name;

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        }, function () {
        });
      },
      loadTaskById(id, ignorePopulate) {
        let self = this;
        piincHttp.get('tasks/' + id).then(function (response) {
          self.selectedProject = response.data;
          if (!ignorePopulate) {
            self.populateForm()
          }
        }, function () {
        });
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope === code && object.type === 'System' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'System',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
            piincHttp.get(self.getWordpressLink + self.myGuides[0].external_id).then(function(response) {

              if (code === 'system_assessment') {
                self.wpSystemAssessmentGuide = response.data
              }

            }, function() {});
          }
        }, function() {});
      },
      onFileChange(file) {
        let files = file.target.files;
        let self = this;
        let formData = new FormData();
        let sizeError = false;

        for (var i = 0; i < files.length; i++) {
          // Equal to 10 MB
          if (files[i].size > 10485760) {
            sizeError = true;
          }
          formData.append('document[]', files[i]);
        }

        formData.append("name", "documents");
        formData.append("model_type", this.selectedProject.who_am_i);
        formData.append("model_id", this.selectedProject.id);

        if (sizeError) {
          this.error = this.$t('system.file_size')
        } else {
          piincHttp.post('documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function () {
            self.error = ''
            self.loadTaskById(self.selectedProject.id, true);
          }, function (error) {
            self.error = error.data.message;
            if (error.data.errors['document.0']) {
              self.error += ' ' + error.data.errors['document.0'][0]
            }
          })
        }
      },
      getIcon(object) {
        let foundObject = {}
        let responseOptions = JSON.parse(object.response_type_options)

        foundObject = _.find(responseOptions, function(item) {
          return item.title === object.response;
        });

        if (foundObject) {
          return foundObject.value.value
        } else {
          return ''
        }

      },
      loadTasks() {
        let self = this;
        // Call API for open tasks

        this.loading = true;

        if(self.project.type === "risk_assessment" || self.project.type === "bia_assessment") {
          piincHttp.get('projects/' + self.project.slug + '/tasks', {
            params:
              {
                client: this.getClient.slug,
                not_code: 'system_impact_assessment',
                model_type: self.object.who_am_i,
                model_id: self.object.id,
                per_page: 100
              }
          }).then(function (response) {
            self.tasks = response.data.items;
            if (self.tasks && self.tasks.length) {
              self.modalSize = 'lg';
            }
            self.loading = false;
          }, function () {
          });
        }else{
          piincHttp.get('projects/' + self.project.slug + '/tasks', {
            params:
                {
                  client: this.getClient.slug,
                  not_code: 'update_measures',
                  per_page: 100
                }
          }).then(function (response) {
            self.tasks = response.data.items;
            if (self.tasks && self.tasks.length) {
              self.modalSize = 'lg';
            }
            self.loading = false;
          }, function () {
          });
        }
      },
      loadSystemRisks() {
        let self = this;
        // Call API for open tasks
        piincHttp.get('projects/' + self.project.slug + '/tasks', {
          params:
            {
              client: this.getClient.slug,
              code: 'system_impact_assessment',
              model_type: self.object.who_am_i,
              model_id: self.object.id,
              per_page: 100
            }
        }).then(function (response) {
          self.systemRisks = response.data.items;
        }, function () {
        });
      },
    },
    created() {
      this.activity = this.object;
      this.loadGuides('system_assessment');
    }
  }
</script>
